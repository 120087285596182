/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionsUsuarioDocumentos from '../../store/usuarioDocumento/actions';
import { withRouter } from 'react-router-dom';
import { DialogDefault, ArquivoContainer, UsuarioDocumentoSelect, DatePicker } from '../../components';
import * as selectors from '../../store/usuarioDocumento/reducer';
import moment from 'moment';

const schema = {
  id: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  dataDocumento: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
  validoAte: {
    presence: { allowEmpty: false, message: 'é obrigatório' },
  },
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    marginTop: 0
  },
  fieldContainer: {
    paddingBottom: '0px !important'
  }
}));

const DocumentoForm = ({ open, usuarioDocumento, onClose, isCertificado, onSave, documentoUsuarios, documentoUsuarioIds, readOnly }) => {
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedDocument, setSelectedDocument] = useState({});
  const [disabledValidoAte, setDisabledValidoAte] = useState(true);
  const [documentoIdsFilter, setDocumentoIdsFilter] = useState([]);

  useEffect(() => {
    if (usuarioDocumento) {
      setValues(usuarioDocumento);
      setDocumentoIdsFilter(documentoUsuarioIds.filter(d => d !== usuarioDocumento.id));
    } else {
      setValues({});
      setDocumentoIdsFilter(documentoUsuarioIds);
    }
  }, [usuarioDocumento, documentoUsuarioIds]);

  const onSaveLocal = () => {
    const erros = validate(values, schema);
    setErrors(erros);
    if (!erros) {
      onSave({ ...values, nome: selectedDocument.nome || values.nome, tipo: isCertificado ? "CERTIFICADO" : "DOCUMENTO"  });
      onCloseLocal();
    }
  }

  const onCloseLocal = () => {
    setErrors({});
    setValues({});
    setSelectedDocument({});
    setDisabledValidoAte(true);
    setDocumentoIdsFilter([]);
    onClose();
  }

  const handleChangeDocument = documentoId => {
    const documento = documentoUsuarios.filter(d => d.id === documentoId)[0]

    let validoAte = null
    if (documento && documento.possuiValidade && values.dataDocumento) {
      validoAte = moment(values.dataDocumento).add(documento.validadeEmAnos, 'years').valueOf()
    }

    setValues({ ...values, "id": documentoId, validoAte });
    setSelectedDocument(documento);
    setDisabledValidoAte(documento && documento.possuiValidade)
  };

  const handleChangeDataDocumento = data => {
    const dataDocumento = data.valueOf();
    let validoAte = null;
    if (selectedDocument && selectedDocument.possuiValidade) {
      validoAte = moment(dataDocumento).add(selectedDocument.validadeEmAnos, 'years').valueOf()
    }

    setValues({ ...values, dataDocumento, validoAte });
  };

  return (
    <DialogDefault
      open={open}
      handlerCancel={onCloseLocal}
      handlerConfirm={onSaveLocal}
      title={isCertificado ? "Certificado" : "Documento"}
      confirmMessage="Vincular"
      contentClassName={classes.contentClassName}
      maxWidth="md"
      readOnly={readOnly}
    >
      <form autoComplete="off" noValidate>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item md={8}>
            <UsuarioDocumentoSelect
              label={isCertificado ? "Certificado" : "Documento"}
              tipoDocumento={isCertificado ? "CERTIFICADO" : "DOCUMENTO"}
              name="id"
              isRequired
              onSelect={handleChangeDocument}
              value={values.id}
              error={errors && errors.id}
              filterIds={documentoIdsFilter}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4} className={classes.fieldContainer}>
            <DatePicker
              label={`Data ${isCertificado ? "Certificado" : "Documento"}`}
              onChange={handleChangeDataDocumento}
              value={values.dataDocumento}
              isRequired
              error={errors && errors.dataDocumento}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={4} className={classes.fieldContainer}>
            <DatePicker
              label="Válido até"
              onChange={data => setValues({ ...values, validoAte: data.valueOf() })}
              value={values.validoAte}
              isRequired={!disabledValidoAte}
              error={errors && errors.validoAte && !disabledValidoAte}
              disabled={readOnly || disabledValidoAte}
            />
          </Grid>
          <ArquivoContainer
            arquivos={values.arquivos}
            onSave={arquivos => setValues({ ...values, arquivos })}
            disabled={readOnly}
          />
        </Grid>
      </form>
    </DialogDefault>
  );
};

const mapStateToProps = state => ({
  documentoUsuarios: selectors.getUsuarioDocumentosOptions(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsUsuarioDocumentos,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentoForm));
