/* eslint-disable react/jsx-max-props-per-line */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import validate from 'validate.js';
import { 
  DialogDefault, 
  DateTimePicker,
  Input,
  InputFormat,
  DefaultSelect,
  ArquivoContainer
} from '../../components';

import * as selectorsAuth from '../../store/auth/reducer';
import * as selectorsNsir from '../../store/nsir/reducer';
import * as selectorsNsmp from '../../store/nsmp/reducer';
import * as actionsNsmp from '../../store/nsmp/actions';
import * as Role from '../../constants/role';
import * as Status from '../../constants/status';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1)
  }, 
  fieldContainer: {
    paddingBottom: '0px !important'
  },
  posteContainer: {
    margin: 12,
    border: theme.palette.bar.border,
    paddingBottom: 10
  },
  posteContainerTitle: {
    background: theme.palette.bar.principal,
    color: theme.palette.white,
    fontSize: 14,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  image: {
    height: 50
  },
  required: {
    color: 'red',
    paddingTop: 5
  },
  dialogContent: {
    overflow: 'auto'
  }
}));

const NsmpModal = ({ open, onCancel, onConfirm, selectedId, nsmps, nsmpSelected, loadNsmpById, readOnly, userRole, 
  nsirStatus, nsirData, nsirTitle }) => {

  const classes = useStyles();
  const [canEditNsmp, setCanEditNsmp] = useState(false);
  const [readOnlyLocal, setReadOnlyLocal] = useState(readOnly);
  const [editFiles, setEditFiles] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    dataFotoDrone: moment().valueOf(),
    fotos: []
  });

  useEffect(() => {
    setReadOnlyLocal(readOnly);
  }, [readOnly])

  useEffect(() => {
    if (selectedId) loadNsmpById(selectedId);
  }, [selectedId, loadNsmpById]);

  useEffect(() => {
    if (nsmpSelected && nsmpSelected.id) {
      setValues(nsmpSelected);
    }
  }, [nsmpSelected]);

  const setSchema = fields => {
    let schema = {};
    fields.forEach(f => {
      schema = { ...schema, [f]: {
        presence: { allowEmpty: false, message: 'é obrigatório' }
      }}
    });
    return schema;
  }

  const validateCoordenadas = data => {
    let errors = {};

    if (nsirStatus === Status.EM_DIGITACAO) {

      if (data.tipoServico === 'NC') {
        return errors;
      }
  
      if (data.latitude && (data.latitude.trim().length !== 9 || !data.latitude.match(/\d{2}(,\d{6})?/))) {
        errors = {...errors, latitude: ['O Campo é inválido']};
      }
  
      if (data.longitude && (data.longitude.trim().length !== 9 || !data.longitude.match(/\d{2}(,\d{6})?/))) {
        errors = {...errors, longitude: ['O Campo é inválido']};
      }
  
      if (data.coordenada && (data.coordenada.trim().length !== 14 || !data.coordenada.match(/\d{5}(:\d{7})?/))) {
        errors = {...errors, coordenada: ['O Campo é inválido']};
      }
  
      if ((!data.latitude || !data.longitude) && !data.coordenada)  {
        errors = {...errors, latitude: ['O Campo é obrigatório'], longitude: ['O Campo é obrigatório'], coordenada: ['O Campo é obrigatório']};
      }

    }

    return errors;
  }

  const getSchema = () => {
    let schema =['prioridade', 'acesso', 'servico', 'acessoVeiculo', 'equipeIndicada', 'fotos'];

    if (nsirData.area === 'RDU') {
      schema.push('endereco');
    }

    if (isNotService()) {
      schema = ['fotos'];
    } else if (nsirStatus === Status.EM_DIGITACAO) {

      schema = ['tipoServico'];
      if (values.tipoServico === 'PL') {
        schema.push('numLinhasPlanilha');
      } else if (values.tipoServico === 'PR') {
        schema.push('nsmpProgramada');
      }

      if (values.tipoServico === 'NC') {
        schema.push('motivo');
      } else if (values.tipoServico !== 'NC' && values.tipoServico !== 'PR') {
        schema.push('nsmp');
      }
    }

    return setSchema(schema);
  }

  const handleChange = (id, value) => {
    setValues({
      ...values,
      [id]: value
    });
  };

  const clearForm = () => {
    setCanEditNsmp(false);
    setEditFiles(false);
    setErrors({});
    setValues({
      fotos: [],
      dataFotoDrone: moment().valueOf(),
    });
  }

  const onCancelLocal = () => {
    clearForm();
    onCancel();
  }

  const getDataFotoDrone = () => {
    let data = moment().valueOf();

    if (values.dataFotoDrone) {
      data = values.dataFotoDrone;
    }
    
    return moment(data).valueOf();
  }

  const onConfirmLocal = () => {
    let errors = {...validate(values, getSchema())};
    if (nsirStatus === Status.EM_DIGITACAO) {
      errors = { ...errors, ...validateCoordenadas(values)};
    }

    setErrors(errors);
    if (!errors || (errors && Object.keys(errors).length === 0)) {
      const updateFiles = editFiles;
      clearForm();
      onConfirm({ 
        ...values, 
        codigo: getCodigo(),
        dataFotoDrone: getDataFotoDrone(),
        updateFiles
      });
    }
  }
  
  const getCodigo = () => {
    if (values && values.id) return values.codigo

    let codigo = Array.isArray(nsmps) && nsmps.length > 0 ? nsmps.filter(n => n.fotoInicio !== true && n.fotoFim !== true && n.analiseRisco !== true).length + 1 : 1;
    while ((codigo+'').length < 3) codigo = '0' + codigo;
    return codigo;
  }

  const isAdmin = () => userRole === Role.ADMIN || userRole === Role.SUPER_ADMIN;

  const getTitle = () => {
    if (userRole === Role.INSPETOR && !selectedId) {
      return `${nsirTitle} > Novo Serviço ${values.codigo}`;
    } else if (values.nsmp) {
      return `${nsirTitle} > Serviço ${values.codigo} > NSMP ${values.nsmp}`;
    } else if (values && values.fotoInicio) {
      return `${nsirTitle} > Foto Inicial`;
    } else if (values && values.fotoFim) {
      return `${nsirTitle} > Foto Final`;
    } else if (values && values.analiseRisco) {
      return `${nsirTitle} > Análise de Risco`;
    } else if (values && values.fotoRegistro) {
      return `${nsirTitle} > Foto Registro ${values.codigo}`;
    }

    return `${nsirTitle} > Serviço ${values.codigo}`;
  }

  const isFotoRegistro = () => values.fotoRegistro === true;
  const isNotService = () => values.fotoInicio === true || values.fotoFim === true || values.analiseRisco === true || isFotoRegistro();

  return (
    <DialogDefault
      open={open}
      handlerCancel={onCancelLocal}
      handlerConfirm={onConfirmLocal}
      title={getTitle()}
      confirmMessage={'Salvar'}
      fullScreen
      contentClassName={classes.dialogContent}
      readOnly={canEditNsmp? false : nsirStatus === Status.EM_DIGITACAO && !isNotService()? false : readOnlyLocal}
      headerAction={isAdmin() && readOnlyLocal ? {
        onClick: () => {
          setReadOnlyLocal(false);
          setCanEditNsmp(true);
        },
        title: 'Editar'
      } : null}
    >
      <Grid container spacing={3} className={classes.root}>

        {!isNotService() && <>
          <Grid item xs={12} sm={1} className={classes.fieldContainer}>
            <Input
              name="codigo"
              label="Código"
              disabled
              value={getCodigo()}
            />
          </Grid>
          <Grid item xs={12} sm={2} className={classes.fieldContainer}>
            <DateTimePicker 
              onChange={data => handleChange('dataFotoDrone', data.valueOf())}
              label="Data da Foto Drone"
              value={values.dataFotoDrone || moment().valueOf()}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={2} className={classes.fieldContainer}>
            <DateTimePicker 
              onChange={data => handleChange('dataFotoDroneFim', data.valueOf())}
              label="Data da Foto Drone Fim"
              value={nsirStatus === Status.EM_INSPECAO && !values.dataFotoDroneFim? moment().valueOf() : values.dataFotoDroneFim}
              disabled={readOnlyLocal || values.equipeIndicada !== 'FAIXA' || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={1} className={classes.fieldContainer}>
            <DefaultSelect
              error={errors && errors.prioridade ? true : false}
              isRequired
              label="Prioridade"
              name="prioridade"
              showEmpty
              options={[
                {id: 'A', nome: 'A'},
                {id: 'B', nome: 'B'},
                {id: 'C', nome: 'C'},
                {id: 'E', nome: 'E'},
                {id: 'U', nome: 'U'},
              ]}
              onSelect={event => handleChange(event.target.name, event.target.value)}
              value={values.prioridade}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={2} className={classes.fieldContainer}>
            <DefaultSelect
              error={errors && errors.acesso ? true : false}
              isRequired
              label="Acesso"
              name="acesso"
              showEmpty
              options={[
                {id: 'FACIL', nome: 'Fácil'},
                {id: 'MEDIO', nome: 'Médio'},
                {id: 'DIFICIL', nome: 'Difícil'}
              ]}
              onSelect={event => handleChange(event.target.name, event.target.value)}
              value={values.acesso}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={2} className={classes.fieldContainer}>
            <DefaultSelect
              error={errors && errors.acessoVeiculo ? true : false}
              isRequired
              label="Acesso Veiculo"
              name="acessoVeiculo"
              showEmpty
              options={[
                {id: 'true', nome: 'Sim'},
                {id: 'false', nome: 'Não'}
              ]}
              onSelect={event => handleChange(event.target.name, event.target.value)}
              value={values.acessoVeiculo}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={2} className={classes.fieldContainer}>
            <DefaultSelect
              error={errors && errors.equipeIndicada ? true : false}
              isRequired
              label="Equipe Indicada"
              name="equipeIndicada"
              showEmpty
              options={[
                {id: 'DUPLA', nome: 'Dupla'},
                {id: 'TRIO', nome: 'Trio'},
                {id: 'PESADA', nome: 'Pesada'},
                {id: 'LINHA_VIVA', nome: 'Linha Viva'},
                {id: 'PODA', nome: 'Poda'},
                {id: 'FAIXA', nome: 'Faixa'}
              ]}
              onSelect={event => handleChange(event.target.name, event.target.value)}
              value={values.equipeIndicada}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Input
              error={errors && errors.endereco && nsirData.area === 'RDU' ? true : false}
              isRequired={nsirData.area === 'RDU'}
              label="Endereço"
              name="endereco"
              onChange={event => handleChange(event.target.name, event.target.value)}
              value={values.endereco}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} className={classes.fieldContainer}>
            <Input
              error={errors && errors.servico ? true : false}
              isRequired
              multiline
              rows={2}
              label="Serviço"
              name="servico"
              onChange={event => handleChange(event.target.name, event.target.value)}
              value={values.servico}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.fieldContainer}>
            <Input
              label="Número do Poste"
              name="numeroPoste"
              onChange={event => handleChange(event.target.name, event.target.value)}
              value={values.numeroPoste}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.fieldContainer}>
            <DefaultSelect
              label="Altura do Poste"
              name="alturaPoste"
              showEmpty
              options={[
                {id: '9 m', nome: '9 m'},
                {id: '10 m', nome: '10 m'},
                {id: '11 m', nome: '11 m'},
                {id: '12 m', nome: '12 m'},
                {id: '13 m', nome: '13 m'},
                {id: '15 m', nome: '15 m'},
                {id: '16 m', nome: '16 m'},
                {id: '17 m', nome: '17 m'}
              ]}
              onSelect={event => handleChange(event.target.name, event.target.value)}
              value={values.alturaPoste}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.fieldContainer}>
            <DefaultSelect
              label="Capacidade do Poste"
              name="capacidadePoste"
              showEmpty
              options={[
                {id: '150 daN', nome: '150 daN'},
                {id: '300 daN', nome: '300 daN'},
                {id: '600 daN', nome: '600 daN'},
                {id: '1000 daN', nome: '1000 daN'}
              ]}
              onSelect={event => handleChange(event.target.name, event.target.value)}
              value={values.capacidadePoste}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.fieldContainer}>
            <DefaultSelect
              label="Tipo do Poste"
              name="tipoPoste"
              showEmpty
              options={[
                {id: 'CC', nome: 'CC'},
                {id: 'DT', nome: 'DT'},
                {id: 'MD', nome: 'MD'},
                {id: 'OUTROS', nome: 'OUTROS'}
              ]}
              onSelect={event => handleChange(event.target.name, event.target.value)}
              value={values.tipoPoste}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.fieldContainer}>
            <Input
              label="Estrutura BT"
              name="estruturaBt"
              onChange={event => handleChange(event.target.name, event.target.value)}
              value={values.estruturaBt}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.fieldContainer}>
            <Input
              label="Cabo BT"
              name="caboBt"
              onChange={event => handleChange(event.target.name, event.target.value)}
              value={values.caboBt}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.fieldContainer}>
            <Input
              label="Estrutura MT"
              name="estruturaMt"
              onChange={event => handleChange(event.target.name, event.target.value)}
              value={values.estruturaMt}
              disabled={readOnlyLocal || isNotService()}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.fieldContainer}>
            <Input
              label="Cabo MT"
              name="caboMt"
              onChange={event => handleChange(event.target.name, event.target.value)}
              value={values.caboMt}
              disabled={readOnlyLocal || isNotService()}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Observação (outras informações)"
            name="observacao"
            onChange={event => handleChange(event.target.name, event.target.value)}
            multiline
            rows={2}
            value={values.observacao}
            disabled={readOnlyLocal || isNotService()}
          />
        </Grid>
        </>}
        {isFotoRegistro() && <Grid item xs={12} className={classes.fieldContainer}>
          <Input
            label="Detalhes"
            name="observacao"
            onChange={event => handleChange(event.target.name, event.target.value)}
            multiline
            rows={4}
            value={values.observacao}
            disabled={readOnlyLocal}
          />
        </Grid>}
        {(Role.DIGITADOR === userRole || Role.ADMIN === userRole) && nsirStatus !== Status.EM_INSPECAO && !isNotService() &&
          <Grid container spacing={3} className={classes.posteContainer}>
            <Grid item xs={12} className={classes.posteContainerTitle}>
              Informações da NSMP
            </Grid>
            <Grid item xs={3} className={classes.fieldContainer}>
              <DefaultSelect
                error={errors && errors.tipoServico ? true : false}
                isRequired={isAdmin() ? false : nsirStatus === Status.EM_DIGITACAO}
                label="Tipo de Serviço"
                name="tipoServico"
                showEmpty
                options={[
                  {id: 'E', nome: 'E-mail'},
                  {id: 'EM', nome: 'Emergência'},
                  {id: 'L', nome: 'Limpeza de Faixa'},
                  {id: 'M', nome: 'Manutenção'},
                  {id: 'NC', nome: 'Não Cadastrada'},
                  {id: 'OB', nome: 'Objeto na Rede'},
                  {id: 'OS', nome: 'OSAF'},
                  {id: 'PL', nome: 'Planilha'},
                  {id: 'P', nome: 'Poda RDR ou RDU'},
                  {id: 'PR', nome: 'Programada'},
                ]}
                onSelect={event => {
                  setValues({
                    ...values,
                    [event.target.name]: event.target.value || null,
                    numLinhasPlanilha: null,
                    motivo: null,
                    nsmp: event.target.value === "E"? "E-MAIL" : event.target.value === "EM"? "EMERGÊNCIA" : event.target.value === "OS"? "OSAF" : ""
                  });
                }}
                value={values.tipoServico}
                disabled={canEditNsmp ? false : nsirStatus !== Status.EM_DIGITACAO}
              />
            </Grid>
            <Grid item xs={3} className={classes.fieldContainer}>
              <DefaultSelect
                error={errors && errors.motivo ? true : false}
                isRequired={nsirStatus === Status.EM_DIGITACAO && values.tipoServico === 'NC'}
                label="Motivo"
                name="motivo"
                showEmpty
                options={[
                  {id: 'NCA', nome: 'Anotação Indevida'},
                  {id: 'NCD', nome: 'Duplicidade'},
                  {id: 'NCP', nome: 'Prioridade'},
                  {id: 'NCPL', nome: 'Planilha'},
                ]}
                onSelect={event => {
                  setValues({
                    ...values,
                    [event.target.name]: event.target.value || null,
                  });
                }}
                value={values.motivo}
                disabled={canEditNsmp ? values.tipoServico !== 'NC' : nsirStatus !== Status.EM_DIGITACAO || (nsirStatus === Status.EM_DIGITACAO && values.tipoServico !== 'NC')}
              />
            </Grid>
            <Grid item xs={3} className={classes.fieldContainer}>
              <Input
                error={errors && errors.numLinhasPlanilha ? true : false}
                isRequired={values.tipoServico === 'PL'}
                name="numLinhasPlanilha"
                label="N° Linhas"
                value={values.numLinhasPlanilha}
                onChange={event => handleChange(event.target.name, event.target.value)}
                disabled={canEditNsmp ?  values.tipoServico !== 'PL' : (nsirStatus !== Status.EM_DIGITACAO || (nsirStatus === Status.EM_DIGITACAO && values.tipoServico !== 'PL'))}
              />
            </Grid>
            <Grid item xs={3} className={classes.fieldContainer}>
              <Input
                error={errors && errors.nsmpProgramada ? true : false}
                isRequired={values.tipoServico === 'PR'}
                name="nsmpProgramada"
                label="N° Nsmp Programada"
                value={values.nsmpProgramada}
                onChange={event => handleChange(event.target.name, event.target.value)}
                disabled={canEditNsmp ? false : nsirStatus !== Status.EM_DIGITACAO}
              />
            </Grid>
            <Grid item xs={3} className={classes.fieldContainer}>
              <Input
                error={errors && errors.nsmp ? true : false}
                isRequired={nsirStatus === Status.EM_DIGITACAO && values.tipoServico !== "NC" && values.tipoServico !== "PR"}
                name="nsmp"
                label="N° Nsmp Nova"
                value={values.nsmp}
                onChange={event => handleChange(event.target.name, event.target.value)}
                disabled={canEditNsmp ? values.tipoServico === 'E' || values.tipoServico === 'EM' || values.tipoServico === 'OS' || values.tipoServico === 'PR'
                  : (nsirStatus !== Status.EM_DIGITACAO || (nsirStatus === Status.EM_DIGITACAO && (values.tipoServico === 'E' || values.tipoServico === 'EM' || values.tipoServico === 'OS' || values.tipoServico === 'PR')))}
              />
            </Grid>
            <Grid item xs={3} className={classes.fieldContainer}>
              <InputFormat
                error={errors && errors.latitude ? true : false}
                helperText={errors && values.latitude ? 'O campo é inválido' : 'Latitude e Longidute ou Coordenada deve ser preenchido'}
                name="latitude"
                label="Latitude"
                value={values.latitude}
                onChange={event => handleChange(event.target.name, event.target.value)}
                disabled={canEditNsmp ? false : nsirStatus !== Status.EM_DIGITACAO}
                format="##,######"
              />
            </Grid>
            <Grid item xs={3} className={classes.fieldContainer}>
              <InputFormat
                error={errors && errors.longitude ? true : false}
                helperText={errors && values.longitude ? 'O campo é inválido' : 'Latitude e Longidute ou Coordenada deve ser preenchido'}
                name="longitude"
                label="Longitude"
                value={values.longitude}
                onChange={event => handleChange(event.target.name, event.target.value)}
                disabled={canEditNsmp ? false : nsirStatus !== Status.EM_DIGITACAO}
                format="##,######"
              />
            </Grid>
            <Grid item xs={3} className={classes.fieldContainer}>
              <InputFormat
                error={errors && errors.coordenada ? true : false}
                helperText={errors && values.coordenada ? 'O campo é inválido' : 'Latitude e Longidute ou Coordenada deve ser preenchido'}
                name="coordenada"
                label="Absoluta N"
                value={values.coordenada}
                onChange={event => handleChange(event.target.name, event.target.value)}
                disabled={canEditNsmp ? false : nsirStatus !== Status.EM_DIGITACAO}
                format="######:#######"
              />
            </Grid>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Input
                label="Observação do Digitador (outras informações)"
                name="observacaoDigitador"
                onChange={event => handleChange(event.target.name, event.target.value)}
                multiline
                rows={3}
                value={values.observacaoDigitador}
                disabled={canEditNsmp ? false : nsirStatus !== Status.EM_DIGITACAO}
              />
            </Grid>
          </Grid> 
        }
        <ArquivoContainer 
          arquivos={values.fotos}
          onSave={fotos => {
            setEditFiles(true);
            setValues({ ...values, fotos });
          }}
          disabled={canEditNsmp? false : readOnlyLocal}
        />
        <Grid item xs={12} className={classes.fieldContainer}>
          {errors && errors.fotos && 
            <p className={`MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-marginDense Mui-error ${classes.required}`}>*Imagens são obrigatórias</p>
          }
        </Grid>
      </Grid>
    </DialogDefault>
  );
};

NsmpModal.propTypes = {
  onCancel: PropTypes.func.isRequired, 
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedId: PropTypes.string,
  nsmps: PropTypes.array.isRequired,
  nsmpSelected: PropTypes.object.isRequired,
  loadNsmpById: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  nsirData: PropTypes.object.isRequired,
  nsirTitle: PropTypes.string.isRequired,
};

NsmpModal.defaultProps = {
  selectedId: undefined,
};

const mapStateToProps = state => ({
  nsmps: selectorsNsmp.getNsmps(state),
  nsmpSelected: selectorsNsmp.getNsmpSelected(state),
  userRole: selectorsAuth.getRole(state),
  nsirStatus: selectorsNsir.getNsirStatus(state),
  nsirData: selectorsNsir.getNsirSelected(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsNsmp
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NsmpModal);
